import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import '../App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import InfoPanel from '../infoPanel';
import { DefaultPlayer as Video } from 'react-html5video';
import LazyLoad from 'react-lazy-load';
import videosList from './videos';



function ButtonVideos(props) {
  const [setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    navigate(-1);
  }

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 300: 1, 600: 2, 1400: 3 }}
    >
      <Masonry gutter={20}>
        {videosList.filter(videos => videos.category === `button`).map(filteredVideos => (
          <div className="vid">
            <Link to={`${filteredVideos.name}`}>
              <div>
                <LazyLoad offset={0}>
                  <Video autoPlay loop muted
                    controls
                    onCanPlayThrough={() => {
                      // Do stuff
                    }}>
                    <source key={filteredVideos.id} src={filteredVideos.src} type="video/webm" />
                  </Video>
                </LazyLoad>
                <div className="details">
                  <div className="detail">{filteredVideos.name}</div>
                  <div className="detail">{filteredVideos.category}</div>
                </div>
                <div className="link">
                  <a target='_blank' href={"https://www." + filteredVideos.link}>
                    {filteredVideos.link}
                  </a>
                </div>
              </div>
            </Link>
            <Routes>
              <Route path={`${filteredVideos.name}`} element={
                <InfoPanel
                  name={filteredVideos.name}
                  videoID={filteredVideos.id}
                  videoSRC={filteredVideos.src}
                  category={filteredVideos.category}
                  website={filteredVideos.link} />
              }>
              </Route>
            </Routes>
          </div>

        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
}




export default ButtonVideos;