import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
  NavLink
} from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Logo from './nicelittledetails.svg'
import AllVideos from './videoComponents/allVideos';
import ButtonVideos from './videoComponents/buttonVideos';
import MenuVideos from './videoComponents/menuVideos';
import CardVideos from './videoComponents/cardVideos';
import TabVideos from './videoComponents/tabVideos';
import LinkVideos from './videoComponents/linkVideos';
import BannerVideos from './videoComponents/bannerVideos';
import SwitchVideos from './videoComponents/switchVideos';


function App() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  const pathname = window.location.pathname;
  return (
    <div>
      <Router>
        <div className='nav'>
          <Menu menuButton={<MenuButton>Component <ChevronDownIcon /></MenuButton>} transition>
            <MenuItem><NavLink to="/">All</NavLink></MenuItem>
            <MenuItem><NavLink to="/banner">Banner</NavLink></MenuItem>
            <MenuItem><NavLink to="/button">Button</NavLink></MenuItem>
            <MenuItem><NavLink to="/card">Card</NavLink></MenuItem>
            <MenuItem><NavLink to="/link">Link</NavLink></MenuItem>
            <MenuItem><NavLink to="/menu">Menu</NavLink></MenuItem>
            <MenuItem><NavLink to="/switch">Switch</NavLink></MenuItem>
            <MenuItem><NavLink to="/tab">Tabs</NavLink></MenuItem>

          </Menu>

        </div>
        <div className='body'>
          <div className='logoHeader'><img src={Logo} /></div>
          <Routes>
            <Route path="/" key={0} element={<AllVideos />}>
            </Route>
            <Route path="/button/*" key={1} element={<ButtonVideos />}>
            </Route>
            <Route path="/banner/*" key={1} element={<BannerVideos />}>
            </Route>
            <Route path="/card/*" key={2} element={<CardVideos />}>
            </Route>
            <Route path="/menu/*" key={3} element={<MenuVideos />}>
            </Route>
            <Route path="/switch/*" key={5} element={<SwitchVideos />}>
            </Route>
            <Route path="/tab/*" key={3} element={<TabVideos />}>
            </Route>
            <Route path="/link/*" key={4} element={<LinkVideos />}>
            </Route>
          </Routes>
          {/* <VideoComponent/> */}
        </div>
      </Router>
      <div className='menuButtons'>
        <div className='secondary' id="tooltip2" data-tooltip-variant="light">About</div>
        <Tooltip anchorSelect="#tooltip2" clickable>
          <div>
            Nice Little Details was created by Nate Chenette and is a highly curated list of small interactions found throughout the web.
          </div>
        </Tooltip>
        <div className='primary' id="tooltip1" data-tooltip-variant="light" onClick={openModal}>Submit</div>
        <Tooltip anchorSelect="#tooltip1" clickable>
          <div>
            Designs and interactions here are highly curated and submitting something doesn't guarantee we'll show it.
            <span>Please submit to submit@nicelittledetails.com.</span>
          </div>
        </Tooltip>
      </div>
    </div>

  );
}

export default App;
