import './App.css';
import React from "react";
import 'animate.css';
import { DefaultPlayer as Video } from 'react-html5video';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate, 
  useLocation
} from "react-router-dom";
import Logo from './nicelittledetails.svg'

function InfoPanel(props) {
  const location = useLocation();
  return (
    <div className='overlay'>
      <div><img src={Logo} /></div>
      <div className='breadCrumb'>
          <Link to="/">Home</Link> <span>/</span> <Link to={props.category}>{props.category}</Link> <span>/</span> {props.name}
      </div>
      <div className='infoPanel animate__animated animate__fadeIn animate__faster'>
        <Video autoPlay loop muted playsinline
          controls
          onCanPlayThrough={() => {
            // Do stuff
          }}>
          <source key={props.videoID} src={props.videoSRC} type="video/webm" />
        </Video>
        <a className="website" target='_blank' href={"https://www." + props.website}>{props.name}</a>
      </div>
    </div>

  );
}

export default InfoPanel;
