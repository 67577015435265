import lemonsqueezy from '../videos/lemonsqueezy.webm'
import twilio from '../videos/twilio.webm'
import stripe from '../videos/stripe.webm'
import skiff from '../videos/skiff.webm'
import swag from '../videos/swagapp.webm'
import sketch from '../videos/sketch.webm'
import ramp from '../videos/ramp.webm'
import framer from '../videos/framer.webm'
import mixpanel from '../videos/mixpanel.webm'
import compound from '../videos/compound.webm'
import gumroad from '../videos/gumroad.webm'
import attio from '../videos/attio.webm'
import codesandbox from '../videos/codesandbox.webm'
import mailchimp from '../videos/mailchimp.webm'
import amie from '../videos/amie.webm'
import linear from '../videos/linear.webm'
import mymind from '../videos/mymind.webm'
import slite from '../videos/slite.webm'
import clarisights from '../videos/clarisights.webm'
import zapier from '../videos/zapier.webm'
import chronicle from '../videos/chronicle.webm'
import equals from '../videos/equals.webm'
import postcards from '../videos/postcards.webm'

const videosList = [
    //23 is next
    {
        id: 22,
        src: postcards,
        name: 'Postcards',
        category: 'switch',
        link: 'designmodo.com/postcards/',
    },
    {
        id: 21,
        src: equals,
        name: 'Equals',
        category: 'tab',
        link: 'equals.com',
    },
    {
        id: 20,
        src: chronicle,
        name: 'Chronicle',
        category: 'button',
        link: 'chroniclehq.com',
    },
    {
        id: 19,
        src: zapier,
        name: 'Zapier',
        category: 'link',
        link: 'zapier.com',
    },
    {
        id: 18,
        src: clarisights,
        name: 'Clarisights',
        category: 'menu',
        link: 'clarisights.com',
    },
    {
        id: 16,
        src: mymind,
        name: 'mymind',
        category: 'link',
        link: 'mymind.com',
    },
    {
        id: 14,
        src: amie,
        name: 'Amie',
        category: 'card',
        link: 'amie.so',
    },
    {
        id: 13,
        src: mailchimp,
        name: 'Mailchimp',
        category: 'button',
        link: 'mailchimp.com',
    },
    {
        id: 11,
        src: attio,
        name: 'Attio',
        category: 'banner',
        link: 'attio.com',
    },
    {
        id: 1,
        src: lemonsqueezy,
        name: 'Lemon Squeezy',
        category: 'button',
        link: 'lemonsqueezy.com',
    },
    {
        id: 12,
        src: codesandbox,
        name: 'Codesandbox',
        category: 'menu',
        link: 'codesandbox.io',
    },
    {
        id: 2,
        src: twilio,
        name: 'Twilio',
        category: 'button',
        link: 'twilio.com',
    },
    {
        id: 15,
        src: linear,
        name: 'Linear',
        category: 'card',
        link: 'linear.app',
    },
    {
        id: 3,
        src: stripe,
        name: 'Stripe',
        category: 'card',
        link: 'stripe.com',
    },
    {
        id: 4,
        src: skiff,
        name: 'Skiff',
        category: 'menu',
        link: 'skiff.com',
    },
    {
        id: 5,
        src: swag,
        name: 'Swag',
        category: 'menu',
        link: 'swagapp.com',
    },
    {
        id: 6,
        src: sketch,
        name: 'Sketch',
        category: 'tab',
        link: 'sketch.com',
    },
    {
        id: 7,
        src: ramp,
        name: 'Ramp',
        category: 'link',
        link: 'ramp.com',
    },
    {
        id: 8,
        src: framer,
        name: 'Framer',
        category: 'tab',
        link: 'framer.com',
    },
    {
        id: 9,
        src: mixpanel,
        name: 'Mixpanel',
        category: 'button',
        link: 'mixpanel.com',
    },
    {
        id: 17,
        src: slite,
        name: 'slite',
        category: 'card',
        link: 'slite.com',
    },
    {
        id: 10,
        src: compound,
        name: 'Compound',
        category: 'button',
        link: 'withcompound.com',
    },
    {
        id: 11,
        src: gumroad,
        name: 'Gumroad',
        category: 'button',
        link: 'gumroad.com',
    },


];

export default videosList;